<template>
    <!-- BREADCRUMBS -->
    <div class="breadcrumb mobile">
        <template v-for="(item, key) in path" :key="key">
            <router-link
                class=""
                :to="
                    key !== 'appart' && key !== 'noRouter'
                        ? getPathAppart(key) + item.id
                        : ''
                "
            >
                <span
                    >{{ key !== "residence" ? "&nbsp;/ " : "" }}{{ item.name }}
                </span>
            </router-link>
        </template>
    </div>
</template>
<script>
export default {
    name: "ResidenceBreadcrumb",
    props: {
        path: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    methods: {
        getPathAppart(key) {
            if (key === "batiment") {
                return "/building/"
            } else if (key === "cage") {
                return "/stairwell/"
            } else {
                return "/residence/"
            }
        },
    },
}
</script>
<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";
</style>
